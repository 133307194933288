body {
  margin: 0;
  font-family: 'Comme', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Comme', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mantine-Progress-root {
  background-color: #cad0ef !important;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

.mantine-xjutu9 {
  height: 0.4rem !important;
}

.mantine-oayzug {
  background-color: white !important;
  padding: 5px 10px !important;
}

.mantine-oayzug[data-checked] {
  background-color: #6472ba !important;
  padding: 5px 7px !important;
}

.mantine-yk2zcy {
  background-color: orange !important;
  padding: 5px 10px !important;
  color: white;
}

.mantine-yk2zcy[data-checked]:not([data-disabled]) {
  padding: 5px 7px !important;
}

.mantine-p4hwqc {
  display: flex !important;
  align-items: center;
  height: 100%;
  width: 100%;
}

.mantine-vcl0d1 {
  padding: 0 !important;
}

.mantine-vcl0d1 button {
  border: 1.5px solid #6472ba !important;
  border-radius: 5px !important;
  min-height: 20px !important;
  min-width: 20px !important;
  color: #6472ba !important;
  background-color: transparent !important;
}

.mantine-2uzuan {
  pointer-events: none !important;
}
.active svg{
  fill: #6472ba !important;
  color: #6472ba !important;
}